import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueFormulate from '@braid/vue-formulate'
import { fr } from '@braid/vue-formulate-i18n'
import VueFormulateDatepickerPlugin from "vue-formulate-datepicker"
import "@sum.cumo/vue-datepicker/dist/Datepicker.css"
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuex from 'vuex';
import setupInterceptors from './services/setupInterceptors';

import Multiselect from 'vue-multiselect'
import VueSignaturePad from 'vue-signature-pad'

Vue.config.productionTip = false

Vue.use(Vuex);
setupInterceptors(store);

// version
Vue.prototype.$version = "0.1.0"

// global variables
Vue.prototype.$urlApi =     "https://dev.oktime.fr/api/"
Vue.prototype.$urlUploads = "https://dev.oktime.fr/uploads/"
Vue.prototype.$urlWeb =     "https://dev.oktime.fr/"
//Vue.prototype.$urlApi =     "https://127.0.0.1:8000/api/"
//Vue.prototype.$urlUploads = "https://127.0.0.1:8000/uploads/"
//Vue.prototype.$urlWeb =     "https://127.0.0.1:8000/"

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBeLK9AVLAVM39bZ_rc_C6tod7B6OkEtHY',
    libraries: 'places'
  }
})

Vue.component('Multiselect', Multiselect)
Vue.use(VueSignaturePad)
Vue.use(VueFormulate, {
  classes: {
    outer: 'form-group',
    input: 'form-control',
    inputHasErrors: 'is-invalid',
    help: 'form-text text-muted',
    errors: 'form-text text-danger'
  },
  plugins: [ fr, VueFormulateDatepickerPlugin ],
  locale: 'fr',
  library: {
    autocomplete: {
      classification: 'text',
    }
  }
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBeLK9AVLAVM39bZ_rc_C6tod7B6OkEtHY',
    libraries: 'places'
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
